// import { IconPhone } from "@tabler/icons-react";
// import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";
import logo from '../assets/images/logo-kar.png';
// import logo1 from '../assets/images/th.jpeg';
import logo1 from '../assets/images/logo-kar.png';
import { Link } from 'react-router-dom';

import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';

function About() {
  return (
    <>
      <section className="about-page">
        <div className="container">
          <div className="about-main">
          {/* <div class="video-poster">

		                				<img src={} alt="poster"/> 
		                				<div class="video-poster-content">
		                					<a href="https://www.youtube.com/watch?v=vBPgmASQ1A0" class="btn-video btn-iframe"><i class="icon-play"></i></a>
		                				</div>
		                			</div> */}
            <img
              className=""
              src={logo1}
              alt="car-renting"
              style={{    width: "400px"}}

            />
            <div className="about-main__text">
              <h3>About Company</h3>
              <h2>Best Affordable Cars at your door step</h2>
            <p>
                  Welcome to <b>Aziz Rent a Car karachi Car Rental</b> , your premier destination for luxury car rentals in the vibrant city of karachi. At Aziz Rent a Car karachi, we redefine the standards of elegance, offering a diverse fleet of upscale vehicles suitable for tourists and residents alike, no matter the occasion.
                </p>
                <b>

Premium Variety:
                </b>
            <p>
Choose from enticing cars by top brands like Hyundai, Audi, Honda, Toyota, BMW, Mercedes, Lamborghini, Audi, Porsche, and Rolls Royce. Enjoy a premium experience without breaking the bank.

            </p>
<b>

Imperial Service:
</b>
<p>

Discover opulence on a budget with Aziz Rent a Car karachi. Our dedicated support team ensures a seamless and transparent rental process for an exceptional journey.
</p>
<center>
<Link to="/about" class="btn btn-outline-primary" style={{width:"150px"}}><span>More About Us</span><i class="icon-long-arrow-right"></i></Link>
</center>
              
              {/* <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                      <img src={Box1} alt="car-icon" />
                  <span>
                    <h4>50</h4>
                    <p>Car Types</p>
                  </span>  
                  
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box2} alt="car-icon" />
                  <span>
                    <h4>85</h4>
                    <p>Rental Outlets</p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box3} alt="car-icon" className="last-fk" />
                  <span>
                    <h4>75</h4>
                    <p>Repair Shop</p>
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <PlanTrip />
        </div>
      </section>
      {/* <br />
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
 
        <h2>Welcome to Aziz Rent a Car karachi Rent a Car!</h2>
        <p> Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models like the KIA , Hyundai and MG. At Aziz Rent a Car karachi, we're committed to delivering an unparalleled experience of comfort, convenience, and excellence.</p>
       <br /> <p> <h2>🌟 Our Mission</h2>
 Redefining the rental car experience through quality service and customer satisfaction. Whether it's a business trip or a family vacation, we ensure every journey is a memorable adventure.</p>
 <br /> <p><h2>🚗 Fleet Excellence</h2> Experience peak performance with our meticulously maintained fleet, featuring the efficiency of the Toyota Corolla anKIA , Hyundai the MG. Choose Aziz Rent a Car karachi for quality and precision.</p>
 <br /> <p><h2>🚗 Rental Solutions</h2> From short-term daily rentals to long-term leases, we provide customizable packages to suit your unique needs. With our commitment to versatility, you'll have the perfect vehicle for every occasion.</p>
 <br /> <p><h2>📅 Book Now</h2> Elevate your travel experience with Aziz Rent a Car karachi Rent a Car! Book with us today and experience top-tier transportation solutions.</p>
                 <h2>Welcome to Aziz Rent a Car karachi Rent a Car</h2>

<p>Your premier destination for top-notch rental car services. Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models such as the Toyota Corolla and Honda HR-V. As your trusted partner in transportation, we are committed to delivering an unparalleled experience that combines comfort, convenience, and excellence.</p>

<h2>Our Mission</h2>

<p>At Aziz Rent a Car karachi Rent a Car, our mission is to redefine the rental car experience through a seamless fusion of quality service and customer satisfaction. Whether it's a business trip or a family vacation, our dedication to providing exceptional services ensures that every journey is not just a trip but a memorable adventure.</p>

<h2>Fleet Excellence</h2>

<p>Experience the pinnacle of performance with our meticulously maintained fleet, showcasing the efficiency of the Toyota Corolla and the style of the Honda HR-V. Our commitment to excellence extends to every vehicle, guaranteeing a smooth and enjoyable ride. Choose Aziz Rent a Car karachi Rent a Car for a journey that reflects our passion for quality and precision.</p>

<h2>Pickup and Drop-off Services</h2>

<p>Embrace the convenience of our tailored pickup and drop-off services. At Aziz Rent a Car karachi Rent a Car, we understand the value of your time, and our services are designed to make your experience seamless. Whether you prefer doorstep delivery or choose to pick up your vehicle at our location, our flexible options cater to your schedule, ensuring a stress-free rental experience.</p>

<h2>Rental Solutions</h2>

<p>Unlock a world of possibilities with our rental solutions. From short-term daily rentals to long-term leases, Aziz Rent a Car karachi Rent a Car provides customizable packages to suit your unique needs. Our commitment to versatility ensures that you have the perfect vehicle for every occasion, allowing you to focus on the journey ahead.</p>

<p>Choose Aziz Rent a Car karachi Rent a Car for a rental experience that goes beyond expectations. With our dedication to excellence, a diverse and well-maintained fleet, and services optimized for your convenience, we are your ultimate destination for top-tier transportation solutions. Book with us today and elevate your travel experience to new heights.</p>

            </div>
            </div>
            </div> */}


    </>
  );
}

export default About;
