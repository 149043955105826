import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
// import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import WhatshotIcon from '@material-ui/icons/Whatshot';

import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import Abou from "./Abou";
import Testimonials from "../components/Testimonials";
import PickCar from "../components/PickCar";
import ChooseUs from "../components/ChooseUs";
import Faq from "../components/Faq";

import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import KIT1 from '../assets/images/KIT.jpg';


import Tourism from '../assets/images/Tourism.svg';
// import slid2 from '../assets/images/slid2.webp';
import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import logo from '../assets/images/logo-kar.png';
// import ab2 from '../assets/images/aboutimage.png';
import about from '../assets/images/about1.png';
import ab from '../assets/images/ab.jpg';
import ab15 from '../assets/images/ab1.jpg';
import ab16 from '../assets/images/ab2.jpg';


// import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
// import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
// import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';
import ch34 from '../assets/images/Unt.png';
import ch343 from '../assets/images/Unt1.jpg';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
import ch344444 from '../assets/images/dollar-bill.png';
import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
// import ch344444444 from '../assets/images/KIT3.jpg';
import Toyo from '../assets/images/Toyota-Corolla_20230829_201645_0000-2-1024x767.png';
import Toyo2 from '../assets/images/Toyota Land Cruiser V8.png';
import Toyo3 from '../assets/images/Prado TX.png';
import Toyo4 from '../assets/images/toyota-coaster-bus-car-rental-coaster-bus-3f48fa740baeaaeb202cf19c12b8bc31.png';
import Toyo51 from '../assets/images/moving-black-car-road.jpg';
import Toyo511 from '../assets/images/center.png';
// import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
// import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
// import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import IconBox from './iconBox';
import Carousel1 from './Carosel';
import Carousel2 from './Carosel1';
// import Carousel3 from './Carosel2';
import Carousel4 from './Carosel3';
// import Carousel5 from './Carosel4';
// import Carousel6 from './Carosel5';
// import Carousel7 from './Carosel6';
import Carousel8 from './Carosel7';
// import Ico from './iconBox';
// import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";


// import ch6 from '../asset1/images/contact-header-bg.jpg';
import ch1 from '../assets/images/pd1-724x1024.jpg';
// import ch2 from '../assets/images/pd2-724x1024.jpg';
// import ch3 from '../assets/images/pd3-724x1024.jpg';
// import ch4 from '../assets/images/pd4-724x1024.jpg';
// import ch5 from '../assets/images/pd5-724x1024.jpg';




const Home = ( props ) => {
   
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
    // const [Categories , setCategories] = useState([])
    // const [Categories1 , setCategories1] = useState([])
    // const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [product10 , setproduct10] = useState([])
    const [product11 , setproduct11] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [SliderPhoto8 , setSliderPhoto8] = useState([])
    // const [ArrivalPhoto , setArrivalPhoto] = useState([])
    // const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    // const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    // const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    // const [chk1 , setchk1] = useState(true)
    // const [chk2 , setchk2] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])


    const [CarBookPrice , setCarBookPrice] = useState([])
    
    // const [Ite1 , setIte1] = useState(1)
    // const [Ite2 , setIte2] = useState(1)
    
    // const [fg , s/etfg] = useState(true)




    const [pickup1 , setpickup1] = useState("")
    const [drop1 , setdrop1] = useState("")
    const [pickup2 , setpickup2] = useState("")
    const [drop2 , setdrop2] = useState("")
    const [pickup3 , setpickup3] = useState("")
    const [drop3 , setdrop3] = useState("")
    const [pickup4 , setpickup4] = useState("")
    const [drop4 , setdrop4] = useState("")
    const [pickup , setpickup] = useState("")
    const [drop , setdrop] = useState("")
    const [choosetime , setchoosetime] = useState("")
    const [pickupdate , setpickupdate] = useState("")
    const [dropdate , setdropdate] = useState("")
    const [phone , setphone] = useState("")
    const [car , setcar] = useState("")
    const [email , setemail] = useState("")
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
    

    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
 
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
//         fetch("https://falcon-rides-main-back.vercel.app/slider-photo",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res=>res.json())
//         .then(res1=>{
//             console.log(res1[0],res1);
//             setSliderPhoto(res1)
//           })
//         fetch("https://falcon-rides-main-back.vercel.app/offer-photo",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res=>res.json())
//         .then(res1=>{
//             // console.log(res1[0]);
//             setSliderPhoto8(res1)
//           })
    
//         fetch("https://falcon-rides-main-back.vercel.app/all-CarBookPrice",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res2=>res2.json())
//         .then(res3=>{
//             console.log(res3);
//             setCarBookPrice(res3)
        
//         })
       

//         fetch("https://falcon-rides-main-back.vercel.app/CourseAllSubCategories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res2=>res2.json())
//         .then(res3=>{
//             let data = res3.sort((a, b) => {
//                 return a.sort - b.sort;
//             });
//             // console.log(data);
//             setSubClassCategories(data)


//             fetch("https://falcon-rides-main-back.vercel.app/AllCategories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res12=>res12.json())
//         .then(res13=>{
//           let a = []
//           console.log(res3,res13)
//           for (let i = 0; i < res13.length; i++) {
//           for (let j = 0; j < res3.length; j++) {
//             console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
//             if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
//               // a.push("1")
// console.log(i,j)
//               a[i] = "1"
//               break
//             }
//             else{
//               a[i] = "0"
//               // a.pusH(("0"))
//             }
            
//           }
//           }
//           console.log(a)
//           setCate2(a)
//         })
//         })
        fetch("https://falcon-rides-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
//         fetch("https://falcon-rides-main-back.vercel.app/AllCategories",{
//           method: "GET",
//            headers :  {
//            "Content-Type" : "application/json" , 
//        } ,
//       })
//       .then(res2=>res2.json())
//       .then(res3=>{
//         let unique_values = res3
//         .map((item) => item.CategoriesName)
//         .filter(
//             (value, index, current_value) => current_value.indexOf(value) === index
//         );
//           // console.log(res3);
//           // const Data1 = res3.filter((res4,i)=>{
//           //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
//           //     return res4
//           // })
//           // console.log(Data1,res3)
//           setSubCategories(unique_values)
//           // setSubCategoriesname(res3)
//           fetch("https://falcon-rides-main-back.vercel.app/MainCatogories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res12=>res12.json())
//         .then(res13=>{

//           let a = []
//           console.log(res13)
//           for (let i = 0; i < res13.length; i++) {
//           for (let j = 0; j < res3.length; j++) {
//             // console.log(res3[j].MainCategories , res13[i].MainCategories)
//             if(res3[j].MainCategories === res13[i].MainCategories){
//               // a.push("1")
// // console.log(i,j)
//               a[i] = "1"
//               break
//             }
//             else{
//               a[i] = "0"
//               // a.pusH(("0"))
//             }
            
//           }
//           }
//           // console.log(a)
//           setCate1(a)
//         })
//       })




      //   localStorage.removeItem("SearchData")
      //   localStorage.removeItem("Data")
      //   localStorage.removeItem("CartPrice")
      //   localStorage.removeItem("CateProduct")

  
      //   var data1 =  JSON.parse(localStorage.getItem("Cart")) 
      //   fetch("https://falcon-rides-main-back.vercel.app/FAQ1",{
      //     method: "GET",
      //      headers :  {
      //      "Content-Type" : "application/json" , 
      //  } ,
      // })
      // .then(res3=>res3.json())
      // .then(res4=>{
      //   let dat1 = []
      //   res4.map((res55,c)=>{
      //       dat1.push(0)
      //   })
      //   setfvalue(dat1)
      //        setdata(res4)
      //        data.map((res,i)=>{
      //         data1[i] = res.text
      //        })

      //     console.log(res4)
      //   })
        fetch("https://falcon-rides-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    const pro1 = []
    const pro2 = []
    const pro3 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
        if ( res7.Arrival2){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro2.push(res7)
        }
        if ( res7.Arrival3){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro3.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct10(pro2)
    setproduct11(pro3)
    setproduct(pro.slice(0,8))
    setproduct1(pro1.slice(0,8))

    setdis(true)
   })
    
 },[])

 
   
    
    
    
    

// const SubmitDataMessage = (e) =>{
//   e.preventDefault()
 

// if(pickup===""){
//   swal("Enter Pickup City")
//   return
// }
// if(drop===""){
//   swal("Enter Drop City")
//   return
// }

//                               fetch("https://falcon-rides-main-back.vercel.app/createbooking1",{
//                                           method: "POST",
//                                           headers :  {
//                                               "Content-Type" : "application/json" , 
//                                           } ,
//                                           body : JSON.stringify({
//                                             pickup ,  
//                                             drop ,
//                                             choosetime ,
//                                             pickupdate , 
//                                             dropdate,
//                                             phone ,
//                                             car ,
//                                             email ,
//                                             Product_Image_Upload : "A",
//                                             // Product_Image_Upload : res2.url,
//                                           })
//                                       })
//                                       .then(res11=>res11.json())
//                                       .then((res12)=>{ 
//                                           if(res12.Error){
//                                             this.setState({displayNone : true})

//                                           swal(res12.Error)
//                                       }
//                                       else{
//                                               swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
//                                               this.setState({
//                                                 // Product_Catagories: "",
//                                                 // Product_Image_Upload : "",
//                                                 url : "",
//                                           })
//                                           this.setState({displayNone : true})

//                                       }
//                                   })
//                                   .catch(err=>{
//                                     swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");

//                                       // swal("There is An Error")                  
//                                       }) 
//                                   // }
//                                   // else{
//                                   //   swal("Successfully Upload your Product!");

//                                   //     swal("Upload Again")                  
//                                   // }
//               // })
//               // .catch(err=>swal("There is an Error"))

//           }  

    
//           const setpickup11 = (e)=>{
//             setpickup1(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop1){
//               let a = false
//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
//                   a = true
//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car1").innerHTML ="No Any Package"
//                   }
//                 }
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup21 = (e)=>{
//             setpickup2(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop2){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === " Toyota Land Cruiser V8"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car2").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup31 = (e)=>{
//             setpickup3(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop3){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car3").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup41 = (e)=>{
//             setpickup4(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop1){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car4").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setdrop11 = (e)=>{
//             setdrop1(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//           if(pickup1){
//             let a = false
//             for (let i = 0; i < CarBookPrice.length; i++) {
//               if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
//                 a = true  
//                 if(CarBookPrice[i].Price> 1){
//                     console.log("gdfg",CarBookPrice[i].Price)
//                     document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                   console.log("gdfg",CarBookPrice[i].Price)
//                   document.getElementById("car1").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

//             }
//             console.log("gdfg")
//           }
//           const setdrop21 = (e)=>{
//             setdrop2(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup2){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car2").innerHTML ="No Any Package"
//                 }
//               }
              
//             }
//             if(!a)  document.getElementById("car2").innerHTML ="No Any Package"
//             }
//           }
//           const setdrop31 = (e)=>{
//             setdrop3(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup3){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car3").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car3").innerHTML ="No Any Package"

//             }
//           }
//           const setdrop41 = (e)=>{
//             setdrop4(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup4){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
//                 a=  true
//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car4").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car4").innerHTML ="No Any Package"

//             }
//           }



//           const setCarPick1 = (e)=>{
//             if(pickup1 && drop1){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Toyota Corolla", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup1,
//                       Drop : drop1
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick2 = (e)=>{
//             if(pickup2 && drop2){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "THonda BRV", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup2,
//                       Drop : drop2
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick3 = (e)=>{
//             if(pickup3 && drop3){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Grand Cabin", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup3,
//                       Drop : drop3
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick4 = (e)=>{
//             if(pickup4 && drop4){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Saloon Coaster", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup4,
//                       Drop : drop4
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
        return (
            
          <div>










<Hero/>












<div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Aziz Rent a Car Karachi with Driver


</h3>

                <div>
            

<Carousel1/>

                       
                </div>  
              </div>

















          <div className="page-wrapper">
            
            {/* End .header */}
            <main className="main" style={{maxHeight:  '100%' , overflowX :  "hidden"}}>






            <BookCar />



<Abou/>








          




{/* 
              <div id="booking" class="section">
		<div class="section-center">
			<div class="container">
				<div class="row">
					<div class="booking-form">
						<form onSubmit={(e)=>SubmitDataMessage(e)}>
					
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={pickup} onChange={(e)=>setpickup(e.target.value)} >
                    <option value="Choose PickUp">Choose PickUp</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                    <span class="select-arrow"></span>

									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                      <option value="Choose Drop Off">Choose Drop Off</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                    <span class="select-arrow"></span>

                  </div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Choose Time</span>
										<input class="form-control" type="time" required placeholder="Choose Time"  value={choosetime} onChange={(e)=>setchoosetime(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Email</span>
										<input class="form-control" type="email" required placeholder="Enter Email"  value={email} onChange={(e)=>setemail(e.target.value)}/>
									</div>
								</div>
							
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP DATE</span>
										<input class="form-control" type="date" required  value={pickupdate} onChange={(e)=>setpickupdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF DATE  </span>
										<input class="form-control" type="date" required  value={dropdate} onChange={(e)=>setdropdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PHONE NUMBER
</span>
                    <input class="form-control" type="number" required placeholder="Enter PHONE NUMBER"  value={phone} onChange={(e)=>setphone(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Car</span>
                    <select class="form-control" required="required" aria-required="true"  value={car} onChange={(e)=>setcar(e.target.value)}>
                      <option value="Saloon Coaster">Saloon Coaster</option>
                      <option value="GRAND CABIN">GRAND CABIN</option>
                      <option value="HONDA BRV">HONDA BRV</option>
                      <option value="Coaster 4c Salon">Coaster 4c Salon</option>
                      <option value="Coaster 5c Salon">Coaster 5c Salon</option>
                      <option value="Grand Cabin 224">Grand Cabin 224</option>
                      <option value="Changan karvaan 7 seater">Changan karvaan 7 seater</option>
                      <option value="Apv 7 seater">Apv 7 seater</option>
                      <option value="Honda BRV">Honda BRV</option>
                      <option value="Voxy 7 seater">Voxy 7 seater</option>
                      <option value="Land Cruiser Limousine">Land Cruiser Limousine</option>
                      <option value="Mercedes C Class">Mercedes C Class</option>
                      <option value="Audi A6">Audi A6</option>
                      <option value="Audi A5">Audi A5</option>
                      <option value="Audi A4">Audi A4</option>
                      <option value="V8 Land cruiser">V8 Land cruiser</option>
                      <option value="Toyota Revo">Toyota Revo</option>
                      <option value="Prado">Prado</option>
                      <option value="Fortuner">Fortuner</option>
                      <option value="Civic 2021">Civic 2021</option>
                      <option value="TOYOTA Grande X">TOYOTA Grande X</option>
                      <option value="Altis X Corolla 1.6">Altis X Corolla 1.6</option>
                      <option value="TOYOTA Corolla Gli">TOYOTA Corolla Gli</option>
                      <option value="TOYOTA COROLLA">TOYOTA COROLLA</option>
</select>
										<span class="select-arrow"></span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-btn">
										<button class="submit-btn">Book Now</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
           <br/>   */}
           <br/>  
           <PickCar />


           <div className="container banner-group-1">
               
               <div className='container' style={{background:"rgb(240, 240, 240)"}}>
                  {/* <div className='container' style={{backgroundImage: 'url('+ab+')',backgroundPosition: "center",
     backgroundRepeat: "no-repeat",
     backgroundSize: "cover",}}> */}
                   <br/>
              <p class="text-center">AFFORDABLE CARS IN karachi
 
 
 </p>
              <h2 class="title mb-4 text-center">Why <b>azizrentacarkarachi.com?</b> 
 
 </h2>
                     <div class="row justify-content-center">
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                 <span class="icon-box-icon" style={{padding  :"0px" , }}>
                                     {/* <i class="icon-local_car_wash"></i> */}
                                     <img src={ch344} alt="" style={{height   : "40px"}}/>
                                 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> First Class Service
 
 </h3>
                                     <p className="cgg">Experience first-class service that surpasses expectations.
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch3444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>24/7 Customer <br /> Support
 </h3>
                                     <p className="cgg" style={{}}>Call us Anywhere Anytime
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch34444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Clean and safe car
 </h3>
                                     <p className="cgg">Efficient safety and cleanliness checks
 
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch3444444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Reservation Anytime  
 </h3>
                                     <p className="cgg">24/7 Online Reservation
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <WhatshotIcon alt="" style={{height   : "40px",color : "black",fontSize:"60px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Quality at Minimum Expense
  
 </h3>
                                     <p className="cgg">Delivering quality at a Minimum Spend.
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch34444444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Free Pick-Up & Drop-Off
 
  
 </h3>
                                     <p className="cgg">Enjoy complimentary pick-up and drop-off services.
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                     </div>
 </div>
 
 
 
 
 






  <div className="container deal-section" style={{background:"white"}}>
                <h3 className="title text-center font-weight-bold mt-3"> Best Car Rental at Aziz Rent a Car karachi
</h3>

                <div>
            

<Carousel1/>

                       
                </div>  
              </div>

           
             



  {/* <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Best Car Rental at Aziz Rent a Car karachi Rent a Car
</h3>

                <div>
            

<Carousel2/>

                       
                </div>  
              </div> */}

           
             


















          


             



                {/* <div className="container">
                <div className="row">
                  <div className="col-12" style={{padding : "0px"}}>
                    <div className="">
                      <div className="bg-image d-flex justify-content-center kjkjkjkj" style={{backgroundImage: 'url('+ch343+')',backgroundRepeat : "no-repeat" ,height:  "410px",backgroundColor : "transparent"}}>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}



              <div className="container deal-section">
                <div>
            

<IconBox/>

                       
                </div>  
              </div>
             



<div className="container banner-group-1">
               
               <div className='container' style={{background:"rgb(240, 240, 240)"}}>
               {/* <div className='container' style={{backgroundImage: 'url('+ab16+')',backgroundRepeat : "no-repeat" , backgroundSize : "cover"}}> */}
                 <br/>
            <p class="text-center" >Services


</p>
            {/* <h2 class="title mb-4 text-center" > <b>azizrentacarkarachi.com</b> 

</h2> */}
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Car Rental Booking
</h3>
                                   <p className="cgg">Experience luxury and convenience with our chauffeur-driven car rental service. Whether you're traveling for business or pleasure, sit back and relax as our professional chauffeurs take the wheel.





</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Tours Booking
</h3>
                                   <p className="cgg" style={{}}>


Book your next adventure with Aziz Rent a Car Karachi and receive the added benefit of a travel plan. Explore with peace of mind, knowing that you're protected against unforeseen circumstances.
</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Pick-Up & Drop-Off
</h3>
                                   <p className="cgg">Effortless pick-up and drop-off services tailored for your convenience. Experience seamless transportation with us. Your journey, our priority.



</p>
                               </div>
                           </div>
                       </div>
                       
                   </div>
</div>
</div>



<div className='container' style={{background :  "#96040e"}}>
          
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#96040e" , display : "flex"}} >

                  <img src={ch34444} alt="" style={{height   : "40px"}}/>

                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>50+ 
</h3>
                          <p className="cgg1" style={{}}>NO. OF CARS

</p>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#96040e" , display : "flex"}} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <img src={ch344} alt="" style={{height   : "40px"}}/>
                    
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 5,000+

</h3>
                          <p className="cgg1">HAPPY CUSTOMERS




</p>
                      </div>
                  </div>
              </div>


              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#96040e" , display : "flex"}} >

                  <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 6
</h3>
                          <p className="cgg1">Operate In Locations

</p>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#96040e" , display : "flex"}} >

                  <img src={ch3444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 24/7
</h3>
                          <p className="cgg1">Quality Support

</p>
                      </div>
                  </div>
              </div>
              
          </div>
</div>

<br />


<ChooseUs />


<Testimonials />




<section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h2>Service Areas</h2>
            </div>

        
            <div className="plan-container__boxes">
          
              
            <li>
  <Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-karachi">
    <center>
      <h6>Rent a Car Karachi</h6>
    </center>
  </Link>
</li>
<li>
  <Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-in-karachi">
    <center>
      <h6>Rent a Car in Karachi</h6>
    </center>
  </Link>
</li>
<li>
  <Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-karachi-pakistan">
    <center>
      <h6>Rent a Car Karachi Pakistan</h6>
    </center>
  </Link>
</li>
<li>
  <Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/rent-a-car-service-in-karachi">
    <center>
      <h6>Rent a Car Service in Karachi</h6>
    </center>
  </Link>
</li>
<li>
  <Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-in-karachi">
    <center>
      <h6>Car Rental in Karachi</h6>
    </center>
  </Link>
</li>
<li>
  <Link className="plan-container__boxes__box" style={{ cursor: "pointer" }} to="/car-rental-karachi">
    <center>
      <h6>Car Rental Karachi</h6>
    </center>
  </Link>
</li>

             
            </div>
          </div>
        </div>
      </section>








            <hr className="mt-2 mb-0" />
              </div>
              <br />



           
              
{/*           
<div class="container">
	                	<h2 class="title text-center mb-3">Our Offices</h2>

	                	<div class="row">
	                		<div class="col-lg-12">
	                			<div class="store">
	                				<div class="row">
	                					<div class="col-sm-5 col-xl-6">
	                						<figure class="store-media mb-2 mb-lg-0">
	                							<img src={logo} alt="image"/>
	                						</figure>
	                					</div>
	                					<div class="col-sm-7 col-xl-6">
	                						<div class="store-content">
	                							<h3 class="store-title">Aziz Rent a Car karachi Car Rental, Tours & transport, 
</h3>
	                							<address>     38 4 B St - Al Karama - karachi - UAE
karachi</address>
	                							<div>                           <a href="tel:+971563619373">+971 56 361 9373</a></div>
	                						
	                						

	                							<h4 class="store-subtitle">Office Open Hours:</h4>
                								<div>Monday - Sunday 24/7 Hours</div>

                								<a href="https://www.google.com/maps/dir//Chand+Tours+%26+Car+Rental+Agency+Lahore+DHA/@31.4751552,74.3199148,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x39190759509a2d3d:0xbe427f23fada2ed5!2m2!1d74.4023161!2d31.4751816?entry=ttu" class="btn btn-link" target="_blank"><span>View Map</span><i class="icon-long-arrow-right"></i></a>
	                						</div>
	                					</div>
	                				</div>
	                			</div>
	                		</div>
	                		</div>

             
</div>
              */}

        
      <Faq/>     
<br />
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
 
        <h2>Welcome to Aziz Rent a Car karachi !</h2>
        <p> Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models like the Hyundai, Audi, Honda, Toyota, BMW, Mercedes, Lamborghini, Audi, Porsche. At Aziz Rent a Car karachi, we're committed to delivering an unparalleled experience of comfort, convenience, and excellence.</p>
       <br /> <p> <h2>🌟 Our Mission</h2>
 Redefining the rental car experience through quality service and customer satisfaction. Whether it's a business trip or a family vacation, we ensure every journey is a memorable adventure.</p>
 <br /> <p><h2>🚗 Fleet Excellence</h2> Experience peak performance with our meticulously maintained fleet, featuring the efficiency of the Hyundai, Audi, Honda, Toyota, BMW, Mercedes, Lamborghini, Audi, Porsche. Choose Aziz Rent a Car karachi for quality and precision.</p>
 <br /> <p><h2>🚗 Rental Solutions</h2> From short-term daily rentals to long-term leases, we provide customizable packages to suit your unique needs. With our commitment to versatility, you'll have the perfect vehicle for every occasion.</p>
 <br /> <p><h2>📅 Book Now</h2> Elevate your travel experience with Aziz Rent a Car karachi ! Book with us today and experience top-tier transportation solutions.</p>
                 
            </div>
            </div>
            </div>



              
              {/* End .blog-posts */}
            </main>
            
            {/* End .footer */}
          </div>
          <button id="scroll-top" title="Back to Top">
            <i className="icon-arrow-up" />
          </button>
       
        </div>
        )
    
}

export default Home;