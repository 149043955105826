import React, { useState , useEffect } from 'react';
// import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import CarImg1 from "../images/cars-big/audi-box.png";
import CarImg2 from "../images/cars-big/golf6-box.png";
import CarImg3 from "../images/cars-big/toyota-box.png";
import CarImg4 from "../images/cars-big/bmw-box.png";
import CarImg5 from "../images/cars-big/benz-box.png";
import CarImg6 from "../images/cars-big/passat-box.png";
import { Link } from "react-router-dom";
// import { IconCar, IconPhone, IconStar } from "@tabler/icons-react";

import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';


import swal from 'sweetalert';

import PickCar from "../components/PickCar";

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';

import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import pay from '../assets/images/pay.png';


import Swal from 'sweetalert2'


function Models(props) {
  const [CategoriesUrl , setCategoriesUrl] = useState("")
  const [Product , setProduct] = useState([])
  const [Product1 , setProduct1] = useState([])
  const [Product2 , setProduct2] = useState([])
  const [Categories , setCategories] = useState([])
  const [fal , setFal] = useState(false)
  const [fal1 , setFal1] = useState(false)
  const [CategoriesName , setCategoriesname] = useState({})
  const [SubCategories , setSubCategories] = useState([])
  const [SubClassCategories , setSubClassCategories] = useState([])
  const [subCate , setsubCate] = useState([])
  const [allproduct , setallproduct] = useState([])
  const [Subproduct , setSubproduct] = useState([])
  const [Subproduct1 , setSubproduct1] = useState([])
  const [SortedData , setSortedData] = useState([])
  const [SortedName , setSortedName] = useState([])
  const [SliderPhoto , setSliderPhoto] = useState([])
  const [DataPart2 , setDataPart2] = useState([])
  const [MainCatogories , setMainCatogories] = useState([])
  const [dispaluy53 , setdispaluy53] = useState(true)
  const [Cate1 , setCate1] = useState([])
  const [Cate2 , setCate2] = useState([])
  // categoriesName subcategories

  useEffect(() =>{

      window.scrollTo(0, 0)
      // console.log( props.match.params);
      let Categoriesname = props.match.params ;
      setCategoriesname(Categoriesname)

      // const Inter = setInterval(()=>{
          //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
          //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

      // var Category =  props.match.url.split("/")
      // setCategoriesUrl(data1)
      // var Category1 =  CategoriesUrl.split("-").join(" ")
      fetch("https://motor-club-main-back.vercel.app/AllProduct",{
                  method: "GET",
                  headers :  {
                  "Content-Type" : "application/json" , 
              } ,
              })
              .then(res7=>res7.json())
              .then(res8=>{
                  setallproduct(res8)
                  const NonActive = res8.filter((res9,i)=>{
                      // console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                      if(res9.status && res9.Product_Catagories === "Cars"  ){
                          return res9 
                      }
                  })

                  setSubproduct(NonActive)
                  console.log(NonActive)
              })

      
      fetch("https://motor-club-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
          fetch("https://motor-club-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          // console.log(res3)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })


      fetch("https://motor-club-main-back.vercel.app/CourseAllSubCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          let data = res3.sort((a, b) => {
              return a.sort - b.sort;
          });
          // console.log(data);
          setSubClassCategories(data)


          fetch("https://motor-club-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res12=>res12.json())
      .then(res13=>{
        let a = []
        console.log(res3,res13)
        for (let i = 0; i < res13.length; i++) {
        for (let j = 0; j < res3.length; j++) {
          console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
          if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
            // a.push("1")
console.log(i,j)
            a[i] = "1"
            break
          }
          else{
            a[i] = "0"
            // a.pusH(("0"))
          }
          
        }
        }
        console.log(a)
        setCate2(a)
      })
      })

      fetch("https://motor-club-main-back.vercel.app/slider-photo",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res=>res.json())
      .then(res1=>{
          // console.log(res1[0]);
          setSliderPhoto(res1)
      
      })


      
                  fetch("https://motor-club-main-back.vercel.app/TagsSort1111",{
                  method: "GET",
                      headers :  {
                      "Content-Type" : "application/json" , 
                  } ,
              })
              .then(res9=>res9.json())
              .then(res8=>{
                  const dta = res8.filter((res7,i)=>{
                      if(res7.MainCategories ==="Cars"  && res7.New_Sub_Categories === Categoriesname.subcategories) {
                          console.log(res7);
                          return res7
                      }
                  })
                  // console.log(dta);
                  setSortedData(dta)
              })
              fetch("https://motor-club-main-back.vercel.app/MainCatogories",{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res2=>res2.json())
            .then(res3=>{
              console.log(res3);
              // setMainCatogories(res3)
              
  
              let dat = []
              res3.map((res55,c)=>{
                  dat.push(res55)
              })
              // console.log(SubClassCategories,asse)
              console.log(dat)
              let data = dat.sort((a, b) => {
                  return a.sort - b.sort;
              });
              setMainCatogories(data)
              
            })
                  fetch("https://motor-club-main-back.vercel.app/AllCategories",{
                  method: "GET",
                      headers :  {
                      "Content-Type" : "application/json" , 
                  } ,
              })
              .then(res4=>res4.json())
              .then(res5=>{
                  let data = res5.filter((res6,i)=>{
                      if (res6.MainCategories === "Cars") return res6
                  })
                  setsubCate(data)
                  // console.log(res5,data);
              })

              fetch("https://motor-club-main-back.vercel.app/CourseAllSubCategories",{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res2=>res2.json())
            .then(res3=>{
                let data = res3.sort((a, b) => {
                    return a.sort - b.sort;
                });
                // console.log(data);
                setSubClassCategories(data)
            })

              

    






      // fetch("https://motor-club-main-back.vercel.app/CategoriesShop",{
      //                         method: "GET",
      //                         headers :  {
      //                             "Content-Type" : "application/json" , 
      //                         }
      //                     })
      //                     .then(res=>res.json())
      //                     .then(res1=>{
      //                         const Pro = res1.filter((res2,i)=>{
      //                             // console.log(res2.Product_Catagories=== Category1,res2.Product_Catagories , " yyy  ", Category1);
      //                             return res2.Product_Catagories === data1
      //                         })
      //                         setProduct(Pro)
      //                         console.log(Pro);
      //                     })
      // // console.log(Catego/ry1);
      //     fetch("https://motor-club-main-back.vercel.app/AllCategories",{
      //         method: "GET",
      //             headers :  {
      //             "Content-Type" : "application/json" , 
      //         } ,
      //     })
      //     .then(res4=>res4.json())
      //     .then(res5=>{
      //         setsubCate(res5)
      //         console.log(res5);
      //     })

      // }
      // else{
      //     var data1 = JSON.parse(localStorage.getItem("Cate")) 

      //     setCategoriesUrl(data1)
          
      //     const Pro = JSON.parse(localStorage.getItem("CateProduct"))
      //                             setProduct(Pro)
      //                             console.log(Pro);
      //         fetch("https://motor-club-main-back.vercel.app/AllCategories",{
      //             method: "GET",
      //                 headers :  {
      //                 "Content-Type" : "application/json" , 
      //             } ,
      //         })
      //         .then(res4=>res4.json())
      //         .then(res5=>{
      //             setCategories(res5)
      //             // console.log(res1);
      //         })
      // }
     
      // },1000)
     
  // return () => clearInterval(Inter);

},[])


const savethedetailproduct = (data) =>{
  localStorage.setItem("Data" , JSON.stringify(data) )
  console.log(data);
}
// useEffect(() => {

//     return () => {
//         clearInterval()
//     }
//   }, [])



const SortData1 = (a ,b) =>{
          setFal1(false)

        const Pro6 = Product.sort((a, b)=> {
              return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
              
          });
          setProduct1(Pro6)
          setFal(true)
          console.log(Product1);



          
}
const SortData2 = (a ,b) =>{
  setFal(false)
        const Pro6 = Product.sort((a, b)=> {
          var nameA = a.Product_Name.toUpperCase(); 
          var nameB = b.Product_Name.toUpperCase(); 
          if (nameA < nameB) {
              return -1;
          }
          if (nameA > nameB) {
              return 1;
          }

          return 0;
          });
          setProduct2(Pro6)
          setFal1(true)
          console.log(Product2);



          
}


const ViewAtDetails = ()=>{
  // console.log("fddddddddddddddddd")
 let data1 =  document.getElementById("view-at-hover")
 data1.setAttribute("class" , "hover-at-view")
}

const NoViewAtDetails = ()=>{
  // console.log("fddddddddddddddddd")
 let data1 =  document.getElementById("view-at-hover")
 data1.removeAttribute("class")
}

// const setSortPrice = (e)=>{
//     SortedName.push(e)
//     console.log(e);
//     if (e === "Low"){
//         const Pro6 = Subproduct.sort((a, b)=> {
//                 return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
              
//             });
//             setSubproduct(Pro6)

//     }
//     if (e === "A"){
//         const Pro6 = Subproduct.sort((a, b)=> {
//             var nameA = a.Product_Name.toUpperCase(); 
//             var nameB = b.Product_Name.toUpperCase(); 
//             if (nameA < nameB) {
//                 return -1;
//             }
//             if (nameA > nameB) {
//                 return 1;
//             }

//             return 0;
//             });
//             console.log(Pro6);
//             setSubproduct(Pro6)

//     }
//     if (fal)
//     setFal(true)
//     else
//     setFal(false)
// }




const sortedAlgo = (e)=>{
  let d = false
  let da = 0
  SortedName.map((res9,a)=>{
      if(res9 === e){
          d = true
          da = a
      }
  })
  if(d){
      SortedName.splice(da,1)
  }
  else{
      SortedName.push(e)
  }
  fetch("https://motor-club-main-back.vercel.app/AllProduct",{
                  method: "GET",
                  headers :  {
                  "Content-Type" : "application/json" , 
              } ,
              })
              .then(res7=>res7.json())
              .then(res8=>{
                  setallproduct(res8)
                  const NonActive = res8.filter((res9,i)=>{
                      // console.log(res9.status , res9.Product_Catagories , "Cars"  ,res9.Product_Sub_Catagories , Categoriesname.subcategories);
                      if(res9.status && res9.Product_Catagories === "Cars"){
                          return res9 
                      }
                  })
              
  if(SortedName.length > 0){
      let Coa = []
      SortedName.map((res22,x)=>{
    console.log(res22,SortedName)
//    console.log(Subproduct)
 const Sort = NonActive.filter((res,i)=>{
      res.Product_Name.split(" ").map((res1,i)=>{
          // console.log(res1)
          if (res1 === res22){
              Coa.push(res)
          }
      })
      res.Product_Title.split(" ").map((res1,i)=>{
          // console.log(res1)
          if (res1 === res22){
              Coa.push(res)
          }
      })
      
          res.tags.map((res1,i)=>{
              if (res1 === res22){
                  // console.log("true")
                  Coa.push(res)
              }
          })
      // }
 })  
  })
   let uniq = [...new Set(Coa)];

 console.log(uniq);
 setSubproduct(uniq)
}
else{
      setSubproduct(NonActive)
  }
 
})
}














// const addtocartproduct = (...data111) =>{
//     //    localStorage.setItem("Data" , JSON.stringify(data) )
//        console.log(data111);


     





//                 document.getElementById("myDi").style.visibility = "visible"
//                 setTimeout(()=>{
//                 document.getElementById("myDi").style.visibility = "hidden"

//                 },1000)
//                 if (data111[0].Product_Price_Discounted === null){
//                   const data =  {...data111[0] ,
//                       Percentage : 0,
//                       DeliveryStatus : "Pending",
//                       Pieces :1 ,
//                       Total_Product_Price  : data111[0].Product_Price *1 }
//                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                   if (data1){
//                   var data3 = data1.map((item) => {
//                       if(item._id === data._id ){
//                           console.log("double");
//                   ;                   localStorage.setItem("double",JSON.stringify(true))
//                   return {...item,
//                       Pieces :1 + item.Pieces,
//                       Total_Product_Price  : (data111[0].Product_Price *1 )+ item.Total_Product_Price}
//                   }
//                   else{
//                   console.log("double not match");
//                   return item
//                   }

//                   })
//                   var data5 =  JSON.parse(localStorage.getItem("double")) 
//                   console.log(DataPart2.length, data3.length,data5);
//                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                   if(data10.length=== data3.length && data5){
//                   console.log("double remove");
//                   localStorage.removeItem("double")
//                   localStorage.setItem("Cart" , JSON.stringify(data3) )

//                   }
//                   else{
//                   console.log("Differet");
//                   var data2 = [...data1 , data]

//                   localStorage.setItem("Cart" , JSON.stringify(data2) )
//                   }
//                   }
//                   else{
//                   console.log("1");
//                   localStorage.setItem("Cart" , JSON.stringify([data]) )

//                   }

//                   }
//                   else if (data111[0].Product_Price_Discounted){
//                   const data =  {...data111[0] ,
//                       Percentage : 0,
//                       DeliveryStatus : "Pending",
//                       Pieces :1 ,
//                       Total_Product_Price  : data111[0].Product_Price_Discounted *1 }
//                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                   if (data1){
//                   var data3 = data1.map((item) => {
//                       if(item._id === data._id ){
//                           console.log("double");
//                   ;                   localStorage.setItem("double",JSON.stringify(true))
//                   return {...item,
//                       Pieces :1 + item.Pieces,
//                       Total_Product_Price  : (data111[0].Product_Price_Discounted *1 )+ item.Total_Product_Price}
//                   }
//                   else{
//                   console.log("double not match");
//                   return item
//                   }

//                   })
//                   var data5 =  JSON.parse(localStorage.getItem("double")) 
//                   console.log(DataPart2.length, data3.length,data5);
//                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                   if(data10.length=== data3.length && data5){
//                   console.log("double remove");
//                   localStorage.removeItem("double")
//                   localStorage.setItem("Cart" , JSON.stringify(data3) )

//                   }
//                   else{
//                   console.log("Differet");
//                   var data2 = [...data1 , data]

//                   localStorage.setItem("Cart" , JSON.stringify(data2) )
//                   }
//                   }
//                   else{
//                   console.log("1");
//                   localStorage.setItem("Cart" , JSON.stringify([data]) )

//                   }

//                   }
//                   else{

//                   }
                
//                   }
                










const setChan = (i) =>{
console.log(i)
 if(      document.getElementById("categ"+i).style.display  ==="block")
document.getElementById("categ"+i).style.display  ="none"
else
document.getElementById("categ"+i).style.display  ="block"
}
const setChan1 = (i,data) =>{
//  let  e =  []
//   for (let s = 0; s < SubCategories.length; s++) {
//     if(data === SubCategories[s].MainCategories)
//     e.push( SubCategories[s])
//   }
//   console.log(i,e)
//   // console.log(a)
//   for (let a = 0; a < e.length; a++) {
//     console.log(a)
//      document.getElementById("cate"+a).style.display  ="none"

//    }
console.log(document.getElementById("cate"+i),i)
   if(      document.getElementById("cate"+i).style.display  ==="block"){
    console.log("yes")
    document.getElementById("cate"+i).style.display  ="none"
  }
  else{
    console.log("yes1")

    document.getElementById("cate"+i).style.display  ="block"
  }
      let a =  []
      SubCategories.map((res1,j)=>{
        if(data === res1.MainCategories){
          a.push(j)
        }
      })
      console.log(a)
  if(a[0] !== i){
      document.getElementById("cate"+a[0]).style.display  ="none"
    }
  // if(i !== 0){
}
const setChan2 = (i) =>{
console.log(i)
 if(      document.getElementById("categp"+i).style.display  ==="block")
document.getElementById("categp"+i).style.display  ="none"
else
document.getElementById("categp"+i).style.display  ="block"
}
const setChan3 = (i,data) =>{
// for (let a = 0; a < SubCategories.length; a++) {
//  console.log(a)
//   document.getElementById("catep"+a).style.display  ="none"

// }
console.log(i)
 if(      document.getElementById("catep"+i).style.display  ==="block")
document.getElementById("catep"+i).style.display  ="none"
else
document.getElementById("catep"+i).style.display  ="block"

let a =  []
SubCategories.map((res1,j)=>{
  if(data === res1.MainCategories){
    a.push(j)
  }
})
console.log(a)
if(a[0] !== i){
document.getElementById("catep"+a[0]).style.display  ="none"
}
}
 
const setChang677 = () =>{
console.log( document.getElementById("navbar-vertical1"))
if( document.getElementById("navbar-vertical1").style.display === "none")
document.getElementById("navbar-vertical1").style.display = "block"
else
document.getElementById("navbar-vertical1").style.display = "none"
}
  const setDisplayBlock = ()=>{
      // console.log("fddddddddddddddddd")
     let data1 =  document.getElementById("dispaly-block")
     if(data1.style.display === "none"){
      data1.style.display = "block"
     }
     else{
      data1.style.display = "none"
     }
  }


  const setSortPrice1 = (e)=>{
    setdispaluy53(!dispaluy53)
    if(Subproduct.length !== 0){

      SortedName.push(e)
      console.log(e,Subproduct);
      if (e === "Low"){
          const Pro6 = Subproduct.sort(function (a, b) {
                  return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                });
                console.log(Pro6);
                let reversed_array = [];
                Pro6.forEach((element) => {
                  reversed_array.unshift(element);
              });
                console.log(Pro6,reversed_array)
                setSubproduct(reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)
  
      }
      if (e === "High"){
          const Pro6 = Subproduct.sort((a, b)=> {
                  return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                  
              });
            //   let reversed_array = [];
            //   Pro6.forEach((element) => {
            //     reversed_array.unshift(element);
            // });
            //   console.log(Pro6,reversed_array)
              setSubproduct(Pro6)
              setdispaluy53(!dispaluy53)
              setdispaluy53(!dispaluy53)

      }
      if (e === "A"){
          const Pro6 = Subproduct.sort((a, b)=> {
              var nameA = a.Product_Name.toUpperCase(); 
              var nameB = b.Product_Name.toUpperCase(); 
              if (nameA < nameB) {
                  return -1;
              }
              if (nameA > nameB) {
                  return 1;
              }
  
              return 0;
              });
              console.log(Pro6);
              setSubproduct(Pro6)
              
            }
            
            setSubproduct1([])
            setdispaluy53(!dispaluy53)

          }
          else{
      
              SortedName.push(e)
              console.log(e);
              if (e === "Low"){
                  const Pro6 = Subproduct.sort((a, b)=> {
                          return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                          
                      });
                      setSubproduct1(Pro6)
          
              }
              if (e === "High"){
                  const Pro6 = Subproduct.sort((a, b)=> {
                          return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                          
                      });
                      setSubproduct1(Pro6.reverse())
          
              }
              if (e === "A"){
                  const Pro6 = Subproduct.sort((a, b)=> {
                      var nameA = a.Product_Name.toUpperCase(); 
                      var nameB = b.Product_Name.toUpperCase(); 
                      if (nameA < nameB) {
                          return -1;
                      }
                      if (nameA > nameB) {
                          return 1;
                      }
          
                      return 0;
                      });
                      console.log(Pro6);
                      setSubproduct1(Pro6)
                      
                    }
                    
                    
                    setSubproduct([])
                  }
                  setdispaluy53(!dispaluy53)

                }

const addtocartproduct = (productDetail) =>{
  Swal.fire({
    title: 'Your Rent a Car in Cart!',
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: 'Coutinue Rent a Car',
    denyButtonText: `View Cart`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      // Swal.fire('Saved!', '', 'success')
    } else if (result.isDenied) {
      props.history.push("/cart")
      // Swal.fire('Changes are not saved', '', 'info')
    }
  })


    
// document.getElementById("myDi").style.visibility = "visible"
// setTimeout(()=>{
// document.getElementById("myDi").style.visibility = "hidden"

// },1000)
console.log(productDetail)
if (productDetail[0].Product_Price_Discounted === null){
const data =  {...productDetail[0] ,
    Percentage : 0,
    DeliveryStatus : "Pending",
    Pieces : 1 ,
    Total_Product_Price  : productDetail[0].Product_Price * 1 }
var data1 = JSON.parse(localStorage.getItem("Cart")) 
if (data1){
var data3 = data1.map((item) => {
    if(item._id === data._id ){
        console.log("double");
;                   localStorage.setItem("double",JSON.stringify(true))
return {...item,
    Pieces : 1 + item.Pieces,
    Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
}
else{
console.log("double not match");
return item
}

})
var data5 =  JSON.parse(localStorage.getItem("double")) 
console.log(DataPart2.length, data3.length,data5);
var data10 =  JSON.parse(localStorage.getItem("Cart")) 

if(data10.length=== data3.length && data5){
console.log("double remove");
localStorage.removeItem("double")
localStorage.setItem("Cart" , JSON.stringify(data3) )

}
else{
console.log("Differet");
var data2 = [...data1 , data]

localStorage.setItem("Cart" , JSON.stringify(data2) )
}
}
else{
console.log("1");
localStorage.setItem("Cart" , JSON.stringify([data]) )

}

}
else if (productDetail[0].Product_Price_Discounted){
const data =  {...productDetail[0] ,
    Percentage : 0,
    DeliveryStatus : "Pending",
    Pieces : 1 ,
    Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
var data1 = JSON.parse(localStorage.getItem("Cart")) 
if (data1){
var data3 = data1.map((item) => {
    if(item._id === data._id ){
        console.log("double");
;                   localStorage.setItem("double",JSON.stringify(true))
return {...item,
    Pieces : 1 + item.Pieces,
    Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
}
else{
console.log("double not match");
return item
}

})
var data5 =  JSON.parse(localStorage.getItem("double")) 
console.log(DataPart2.length, data3.length,data5);
var data10 =  JSON.parse(localStorage.getItem("Cart")) 

if(data10.length=== data3.length && data5){
console.log("double remove");
localStorage.removeItem("double")
localStorage.setItem("Cart" , JSON.stringify(data3) )

}
else{
console.log("Differet");
var data2 = [...data1 , data]

localStorage.setItem("Cart" , JSON.stringify(data2) )
}
}
else{
console.log("1");
localStorage.setItem("Cart" , JSON.stringify([data]) )

}

}
else if (productDetail[0].Size_Discounted[0]){
    
        const data =  {...productDetail[0] ,
            Percentage : 0,
            DeliveryStatus : "Pending",
            Pieces : 1 ,
            Size : productDetail[0].Size[0],
            Product_Price_Discounted : productDetail[0].Size_Discounted[0],
            Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
var data1 = JSON.parse(localStorage.getItem("Cart")) 
if (data1){
var data3 = data1.map((item) => {
    if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
        console.log("double");
;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
}
else{
    console.log("double not match");
    return item
}

})
var data5 =  JSON.parse(localStorage.getItem("double")) 
console.log(DataPart2.length, data3.length,data5);
var data10 =  JSON.parse(localStorage.getItem("Cart")) 

if(data10.length=== data3.length && data5){
console.log("double remove");
localStorage.removeItem("double")
localStorage.setItem("Cart" , JSON.stringify(data3) )

}
else{
console.log("Differet");
var data2 = [...data1 , data]

localStorage.setItem("Cart" , JSON.stringify(data2) )
}
}
else{
console.log("1");
localStorage.setItem("Cart" , JSON.stringify([data]) )

}

}
else{




        const data =  {...productDetail[0] ,
            Percentage : 0,
            DeliveryStatus : "Pending",
            Pieces : 1 ,
            Size : productDetail[0].Size[0],
            Product_Price_Discounted : productDetail[0].Price[0],
            Total_Product_Price  : productDetail[0].Price[0] * 1 }
var data1 = JSON.parse(localStorage.getItem("Cart")) 
if (data1){
var data3 = data1.map((item) => {
    if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
        console.log("double");
;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
}
else{
    console.log("double not match");
    return item
}

})
var data5 =  JSON.parse(localStorage.getItem("double")) 
console.log(DataPart2.length, data3.length,data5);
var data10 =  JSON.parse(localStorage.getItem("Cart")) 

if(data10.length=== data3.length && data5){
console.log("double remove");
localStorage.removeItem("double")
localStorage.setItem("Cart" , JSON.stringify(data3) )

}
else{
console.log("Differet");
var data2 = [...data1 , data]

localStorage.setItem("Cart" , JSON.stringify(data2) )
}
}
else{
console.log("1");
localStorage.setItem("Cart" , JSON.stringify([data]) )

}


}


    

}


const addtocartproduct8 = (productDetail) =>{
  Swal.fire({
    title: 'Your Rent a Car in Cart!',
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: 'Coutinue Rent a Car',
    denyButtonText: `View Cart`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      // Swal.fire('Saved!', '', 'success')
    } else if (result.isDenied) {
      props.history.push("/cart")
      // Swal.fire('Changes are not saved', '', 'info')
    }
  })


      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }


  }


      

}
  return (
    <>
      <section className="models-section">
        <HeroPages name="Vehicle Models" />
        

        <div className="container products mb-3">
                    <div className="row justify-content-center">


                    {Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto"}}>
                                <figure className="product-media">
                                  <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  {/* <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div> */}
                                  {/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body" style={{height:"300px"}}>
                                  <div style={{display:"flex",marginBottom:"15px"}}>
                                 <div style={{height:"47px",width:"4px",background:"grey",marginRight:"5px"}}>

                                 </div>
                                  <h3 className="product-title"   style={{fontSize:"18px",marginTop:"15px",maxWidth:"70%",maxHeight : "59px",overflow : "hidden",fontWeight:"bold",textAlign:"left"}}><Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                                    
                                  <a href="tel:+923004929646">  <AddIcCallIcon  style={{fontSize:"35px",marginTop:"15px",position:"absolute",right:"10%",padding :"5px 5px" ,color:"#96040e",borderRadius:"50px",background:"white",boxShadow:"2px 1px grey",}}/></a>
                                  <a href={`https://wa.me/923004929646?text=Hi *Aziz Rent a car karachi Pk**! I need more info Detail about this ${res.Product_Name}`} target="_blank"> <WhatsAppIcon  style={{fontSize:"35px",marginTop:"15px",position:"absolute",right:"23%",padding :"5px 5px" ,color:"#57bb63",background:"white",boxShadow:"2px 1px grey",borderRadius:"50px"}}/></a>
                                    
                                     </div>
                                 {/* <br /> */}
                                  {/* <span style={{display:"flex"}}>
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                      </span> */}
                                  <hr style={{margin:"0px"}}/>          
                                  { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      }
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",fontWeight:"bold",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         <img src={pay} alt="" style={{marginLeft:"10px",height:20}}/>
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>   */}
                      <div style={{display:"flex",width:"95%",margin:"0px auto 10px auto"}}>
                        
                         <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"center",marginTop:"10px",border:"1px solid grey",padding:"10px 5px",width:"33.33%",cursor :"pointer"}} onClick={()=>{props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}}>
                                 <span>
                                 <span style={{color  :"black", fontWeight:"400"}}>Per Day</span> 
                                 <br />
                                  {res.Product_Price_Discounted ? 
                  <><span style={{fontSize:"14px",fontWeight:"500",color:"red",textDecoration: "line-through"}}>AED {res.Product_Price} </span><br /> <span style={{fontSize:"14px",fontWeight:"500",color:"green"}}>AED {res.Product_Price_Discounted}</span> </>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"500",color:"green"}}>
                 AED {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } 
                                 </span>
                                </div> 
                        
                                <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"center",marginTop:"10px",border:"1px solid grey",padding:"10px 5px",width:"33.33%",cursor :"pointer"}} onClick={()=>{history.push("/categories-weekly/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}}>
                                <span>
                                <span style={{color  :"black", fontWeight:"400"}}>Per Week</span> 
                                <br />
                                 {res.w1 ? 
                 <><span style={{fontSize:"14px",fontWeight:"500",color:"red",textDecoration: "line-through"}}>Rs {res.w1} </span><br /> <span style={{fontSize:"14px",fontWeight:"500",color:"green"}}>Rs {res.w2}</span> </>
                 :
                 <span  style={{fontSize:"14px",fontWeight:"500",color:"green"}}>
                Rs {(res.Product_Price * 7)} <br />
            / Day
                 </span>            
                                   } 
                                </span>
                               </div> 
                       
                        <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"center",marginTop:"10px",border:"1px solid grey",padding:"10px 5px",width:"33.33%",cursor :"pointer"}} onClick={()=>{history.push("/categories-montly/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}}>
                                <span>
                                <span style={{color  :"black", fontWeight:"400"}}>Per Month</span> 
                                <br />
                                 {res.m1 ? 
                 <><span style={{fontSize:"14px",fontWeight:"500",color:"red",textDecoration: "line-through"}}>Rs {res.m1} </span><br /> <span style={{fontSize:"14px",fontWeight:"500",color:"green"}}>Rs {res.m2}</span> </>
                 :
                 <span  style={{fontSize:"14px",fontWeight:"500",color:"green"}}>
                Rs {(res.Product_Price * 30)} <br />
            / Day
                 </span>            
                                   } 
                                </span>
                               </div> 
                       
                       
                       </div>           
                                    {/* <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"#96040e",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span> */}
                                    <span className="shadow-none" style={{borderRadius:"10px",minWidth:"200px",maxWidth:"250px",color:"white",background :"#96040e",padding :"10px 45px",color  :"white",fontSize:"14px",fontWeight:"bold"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>
                              </div>
                            </div>
       )
    })
}
      

                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}

                  <br/>  
           <PickCar />



        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
            <h2 style={{color :"white"}}>Book a car by getting in touch with us</h2>
            <span>
              <PhoneCallbackIcon width={40} height={40} style={{marginTop:"-30px",fontSize:"34px"}} />
              <h3 style={{color :"white"}}>+92 300 4929646</h3>

              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Models;
